import IconGeneric from "../Icon/IconGeneric";
import React, { useMemo } from "react";
import { Content as DomainContent } from "../../domain/Content";
import { useTranslation } from "react-i18next";
import NumberCounter from "../Common/Counters/NumberCounter";
import TimeCounter from "../Common/Counters/TimeCounter";
import { formatContentDateTime } from "../../utils/date";
import useDateFiltersHelper from "../../hooks/filters/use-date-filters-helper";
import useContentsContext from "../../hooks/contexts/use-contents-context";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import DomainFavIcon from "../Common/DomainFavIcon";
import ListImage from "../Common/Content/ListImage";
import ContentType from "../Common/Content/ContentType";
import Author from "../Common/Content/Author";
import PublishDate from "../Common/Content/PublishDate";
import { useNavigate } from 'react-router-dom';

interface Props {
    content: DomainContent
}

const Content = ({ content }: Props) => {
    const { t, i18n } = useTranslation();
    const publishDate = useMemo(() => content.publishDate ? formatContentDateTime(new Date(content.publishDate), i18n.language) : null, [content.publishDate, i18n.language]);
    const { isRealTime } = useDateFiltersHelper();
    const { selectContent } = useContentsContext();
    const { saveState } = useFiltersContext();
    const navigate = useNavigate();

    const handleClick = () => {
        selectContent(content);
        saveState();
        navigate("/content/" + content.uuid);
    };

    const handleIconClick = (e: React.MouseEvent<SVGElement>) => {
        e.stopPropagation();
        window.open(content.url, "_blank", "noreferrer");
    };

    return (
        <div className="cc-content-list__item" onClick={content.uuid ? handleClick : undefined}>
            <ListImage alt={content.title} image={content.image} />
            <div className='cc-content-list__article'>
                <div className="cc-content-list__title">
                    {content.title}
                </div>
                <div className="cc-content-list__info">
                    {content.uuid && <DomainFavIcon url={content.url} alt={content.title} />}
                    <ContentType type={content.type} />
                    { publishDate && <PublishDate date={publishDate} /> }
                    { content.authors && <Author>{content.authors.join(', ')}</Author> }
                    {content.uuid && <IconGeneric
                        iconName="open-in-new-ico"
                        className="mc-icon__generic--size-16 mc-icon--button"
                        onClick={handleIconClick}
                    />}
                </div>
            </div>
            <div className='cc-content-list__data'>
                {/*<TrafficGraph traffic={content.traffic} />*/}
                <div className="cc-content-list__data--pages">
                    <span className="cc-content-list__data--label">{t("pageviews")}</span>
                    <NumberCounter value={content.pageViews} classNamePrefix={"cc-content-list__data--"} />
                </div>
                <div className="cc-content-list__data--time">
                    <span className="cc-content-list__data--label">{t("reading time")}</span>
                    <span className="cc-content-list__data--value">
                        {content.uuid ? <TimeCounter seconds={content.readingTime} classNamePrefix={"cc-content-list__data--"} /> : '-' }
                    </span>
                </div>
                <div className='cc-content-list__data--concurrence'>
                    <span className="cc-content-list__data--label">{t(isRealTime ? "active users" : "unique users")}</span>
                    <NumberCounter value={content.users} isMain={isRealTime} classNamePrefix={"cc-content-list__data--"} />
                </div>
            </div>
        </div>
    );
};

export default Content;
