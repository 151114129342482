import React, { useMemo } from "react";
import { Content } from "../../domain/Content";
import IconGeneric from "../Icon/IconGeneric";
import { formatContentDateTime } from "../../utils/date";
import { useTranslation } from "react-i18next";
import ContentAI from "./AI/ContentAI";
import Summary from "../Summary/Summary";
import useContentsContext from "../../hooks/contexts/use-contents-context";
import FiltersBarList from "../FilterBar/FiltersBarList";
import OrderedFiltersBarList from "../FilterBar/OrderedFiltersBarList";
import ContentTopics from "./ContentTopics";
import Title from "../Common/Content/Title";
import ContentInfo from "../Common/Content/ContentInfo";
import MainChart from "../Chart/MainChart";

interface Props {
    content: Content
}

const ContentDetail = ({ content }: Props) => {
    const { t, i18n } = useTranslation();
    const publishDate = useMemo(() => content.publishDate ? formatContentDateTime(new Date(content.publishDate), i18n.language) : null, [content.publishDate, i18n.language])
    const modificationDate = useMemo(() => content.modificationDate ? formatContentDateTime(new Date(content.modificationDate), i18n.language) : null, [content.modificationDate, i18n.language])
    const { accumulatedSourceStats, analyticsData } = useContentsContext();

    return <div className='cc-content cc-content--ai'>

        <div className="cc-content__item">
            <div className='cc-content-list__article'>
                <Title>
                    {content.title}
                    <IconGeneric iconName="open-in-new-ico" className="mc-icon__generic--size-20 mc-icon--button" onClick={() => {
                        window.open(content.url, "_blank", "noreferrer");
                    }} />
                </Title>

                <ContentInfo
                    url={content.url}
                    type={content.type}
                    category={content.category}
                    author={content.authors.join(', ')}
                    modificationDate={modificationDate}
                    publishDate={publishDate}
                />
                <ContentTopics topics={content.topics} />
                { content.type !== 'section' &&
                    <div className="cc-content-list__ai">
                        <a className="cc-content-list__ai-link" href="#analytics-ai">
                            {t('analytics ai subtitle')}
                            <IconGeneric iconName="arrow-right-ico" className="mc-icon__generic--size-16 is-primary" />
                        </a>
                    </div>
                }
            </div>
            <div className='cc-content-list__picture'>
                <img src={content.image.length > 0 ? content.image : "https://www.google.com/s2/favicons?domain=" + content.url + "&sz=128"} alt={content.title} />
            </div>
        </div>

        <MainChart chart={analyticsData.chart}/>
        <Summary data={analyticsData.summary} />

        <div className="cc-content__item">
            <div className="cc-content__item--charts">
                <FiltersBarList
                    title={t('traffic')}
                    data={analyticsData.stats.traffic}
                    className="cc-chart-bar__channel"
                    group={"traffic"}
                    active={false}
                />
                <OrderedFiltersBarList
                    title={t('source')}
                    data={accumulatedSourceStats}
                    className="cc-chart-bar__source"
                    group={"source"}
                    percentage={false}
                    active={false}
                />
                <OrderedFiltersBarList
                    title={t('platform')}
                    data={analyticsData.stats.platform}
                    className="cc-chart-bar__platform"
                    group={"platform"}
                    active={false}
                />
            </div>
            {content.type !== "section" &&
                <ContentAI content={content} />
            }
        </div>

    </div>
}

export default ContentDetail